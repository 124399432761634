<template>
  <div class="PageSlider part">
    <div class="constrain">
      <div class="slider-box">
        <slider
          v-slot="{ item }"
          :items="payload.slides"
          ref="sliderRef"
          class="slider"
          :slides-per-view="{ desktop: 3 }"
          :slides-per-group="{ desktop: 1 }"
          @progress="onProgress"
        >
          <Go :to="item.link?.[0]?.link" class="page-link li2">
            <Image :src="item?.bild" />
            <line-box class="box" color="white">
              {{ item?.text }}
            </line-box>
            <img :src="item?.icon.normal" class="overlay">
          </Go>
        </slider>
        <div
          v-if="hasPrev"
          class="arrow left"
          :class="{disable: desk}"
          @click="prev"
        >
          <arrow-left />
        </div>
        <div
          v-if="hasNext"
          class="arrow right"
          :class="{disable: desk}"
          @click="next"
        >
          <arrow-right />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import SwiperCore, { Navigation } from 'swiper';
import ArrowLeft from '@/assets/images/Pfeil_Gallery_left.svg';
import ArrowRight from '@/assets/images/Pfeil_Gallery_right.svg';

SwiperCore.use([Navigation]);

export default {
  components: {
    ArrowLeft,
    ArrowRight,
  },
  props: { payload: { type: Object, default: Object }, desk: { type: Boolean, default: true } },
  setup(props) {
    const sliderRef = ref(null);
    const progress = ref(0);

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    const onProgress = (_, nextProgress) => { progress.value = nextProgress; };

    const hasNext = computed(() => progress.value < 1);
    const hasPrev = computed(() => progress.value > 0);

    return {
      prev,
      next,
      hasNext,
      hasPrev,
      onProgress,
      sliderRef,
      boxed: computed(() => props.payload.optionen?.includes('block')),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

.disable {
  @include responsive('display', flex !important, none !important);
}

.slider-box {
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    height: 40px;
    justify-content: center;
    align-items: center;
    width: 40px;
    cursor: pointer;
    display: flex;
    transform: translateY(-50%);
    border-radius: 50%;
    background: white;
    box-shadow: rgb(0 0 0 / 36%) 0px 1px 3px 0px;

    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

:deep(.swiper-slide) {
  aspect-ratio: 5 / 3;
  &:nth-child(1) { --accent: 0, 91, 125; }
  &:nth-child(2) { --accent: 191, 134, 73; }
  &:nth-child(3) { --accent: 80, 42, 85; }
}

.page-link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: var(--speed-fast) ease;
  background: rgba(var(--accent), 0);

  :deep(img) {
    @include full-overlay();
    z-index: -1;
    object-fit: cover;
  }

  .box {
    @extend .n2a;
    color: white;
    position: absolute;
    left: px(10);
    bottom: px(30);
    z-index: 15;
  }

  .overlay {
    @include full-overlay();
    z-index: 10;
    object-fit: cover;
    object-position: 50% 0%;
    transform: scale(1.5);
    mix-blend-mode: multiply;
    opacity: 0;
    pointer-events: none;
    transition:
      transform var(--speed-fast) ease,
      opacity var(--speed-fast) ease;
  }

  &:hover {
    &:nth-child(1) { background: rgba(var(--accent), .6);}

    .overlay {
      opacity: 1;
      transform: scale(2);
    }
  }
}
</style>
